<i18n>
ru:
  leaveFeedback: Оставить отзыв
ua:
  leaveFeedback: Залишити відгук
us:
  leaveFeedback: Leave feedback
</i18n>

<template>
  <header-parts-and-westfold-common
    v-if="isExtraSmall"
    :copyright-years="copyrightYears"
    :link-site="linkSite"
    :external-links-contacts="[]"
    is-in-footer
    :external-links="externalLinksFooter"
    :close-function="
      () => {
        return
      }
    "
  />

  <footer
    v-else
    id="Westfold"
    itemscope
    itemtype="https://schema.org/WPFooter"
  >
    <div class="v-container">
      <div class="v-row">
        <div class="v-col-lg-3 v-col-xl-2 v-logo-order v-mb-md">
          <div class="v-logo">
            <arora-nuxt-link
              itemscope
              :href="appConfig.VueSettingsPreRun.Links.MainLink"
              :label="translate('headerPage.homePage')"
              itemtype="https://schema.org/Brand"
            >
              <arora-nuxt-image
                disable-resize
                preload
                :alt="translate('headerPage.homePage')"
                :image="appConfig.VueSettingsPreRun.FooterLogo"
                itemprop="logo"
              />
            </arora-nuxt-link>
          </div>
        </div>

        <div class="v-col-6 v-col-lg-2 v-offset-xl-1 v-mb-md">
          <ul class="v-footer-extra-links">
            <li
              v-for="(externalLink, index) in externalLinksFooter.filter(
                (_, i) => i % 2 === 0
              )"
              :key="`v-footer-link-${index}`"
            >
              <arora-nuxt-link
                class-name="v-typography-text-4 v-link-text-color-mono-100 v-link-text-color__no-underline"
                :external-link="!externalLink.Url.startsWith('/')"
                :href="externalLink.Url"
                :label="sanitize(externalLink.Name)"
                :open-in-new-tab="!externalLink.Url.startsWith('/')"
              />
            </li>
          </ul>
        </div>
        <div class="v-col-6 v-col-lg-2 v-mb-md">
          <ul class="v-footer-extra-links">
            <li
              v-for="(externalLink, index) in externalLinksFooter.filter(
                (_, i) => i % 2 !== 0
              )"
              :key="`v-footer-link-${index}`"
            >
              <arora-nuxt-link
                class-name="v-typography-text-4 v-link-text-color-mono-100 v-link-text-color__no-underline"
                :external-link="!externalLink.Url.startsWith('/')"
                :href="externalLink.Url"
                :label="sanitize(externalLink.Name)"
                :open-in-new-tab="!externalLink.Url.startsWith('/')"
              />
            </li>
          </ul>
        </div>
        <div class="v-col-5 v-col-lg-2 v-mb-md">
          <common-social-networks />
        </div>
        <div class="v-col-7 v-col-lg-3 v-mb-md">
          <div class="v-mobile-apps">
            <div
              class="v-mobile-apps__qr"
              v-if="
                appConfig.VueSettingsPreRun.FooterEnableAppQRCode &&
                !stringIsNullOrWhitespace(
                  appConfig.VueSettingsPreRun.AppDownloadQRBase64
                )
              "
            >
              <img
                class="v-img-fluid"
                :alt="translate('qrPayPopup.overQr')"
                :src="`data:image/png; base64, ${appConfig.VueSettingsPreRun.AppDownloadQRBase64}`"
              />
            </div>
            <div class="v-mobile-apps__text">
              <div
                class="v-typography-text-6"
                v-html="translate('headerPartsAndWestfoldCommon.moreBonusesInApp')"
              />
            </div>
            <div class="v-mobile-apps__links">
              <common-apps-compact size="small" />
            </div>
          </div>
        </div>

        <div class="v-col-12 v-footer-hr">
          <hr />
        </div>
        <div class="v-col-6 v-col-lg-4 v-d-flex v-align-items-center">
          <meta
            :content="`${currentYear}`"
            itemprop="copyrightYear"
          />
          <span
            v-if="appConfig.VueSettingsPreRun.FooterShowCopyright"
            class="v-typography-text-4 v-color-mono-0 v-mr-xs"
            v-html="copyrightYears"
          />
          <meta
            v-if="appConfig.VueSettingsPreRun.CopyRightOrgName"
            class="v-typography-text-4 v-color-mono-0 v-mr-sm"
            :content="appConfig.VueSettingsPreRun.CopyRightOrgName"
            itemprop="copyrightHolder"
          />
          <arora-nuxt-link
            class-name="v-link-text-color-mono-0 v-typography-text-4 v-link-text-color__no-underline"
            :href="appConfig.VueSettingsPreRun.Links.AgreementLink"
            :label="translate('footerPage.privacy')"
            active-class="v-active"
          />
        </div>
        <div
          class="v-col-6 v-col-sm-5 v-offset-sm-1 v-col-md-4 v-offset-md-2 v-col-lg-2 v-offset-lg-3"
        >
          <motion.button
            class="v-ui-button v-footer-westfold-feedback"
            :aria-label="translate('footerWestfold.leaveFeedback')"
            @click="leaveFeedback"
            :while-press="{ scale: 0.985 }"
            :transition="{
              duration: 0.4,
              scale: { type: 'spring', visualDuration: 0.4, bounce: 0.5 }
            }"
          >
            <span
              class="v-typography-text-4"
              v-html="translate('footerWestfold.leaveFeedback')"
            />
          </motion.button>
        </div>

        <div
          class="v-col-12 v-col-md-3 v-col-lg-2 v-offset-lg-1 v-d-flex v-align-items-center v-justify-content-end v-footer-legal-logo"
          v-if="appConfig.VueSettingsPreRun.FooterShowLegalLogo"
        >
          <arora-nuxt-link
            open-in-new-tab
            :href="linkSite"
            :label="linkSite"
          >
            <span
              class="v-color-mono-0 v-footer-legal-logo__caption"
              v-html="translate('headerPartsAndWestfoldCommon.madeIn')"
            />
            <common-arora-logo compact />
          </arora-nuxt-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { FooterCommon } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

import { motion } from 'motion-v'

defineProps<FooterCommon>()
const appConfig = useAppConfig()
const { sanitize, translate } = useI18nSanitized()

const { stringIsNullOrWhitespace } = useCommon()
const { isExtraSmall } = useWindowSize()

const currentYear = new Date().getFullYear()

function leaveFeedback(): void {
  if (import.meta.client)
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  navigateTo(appConfig.VueSettingsPreRun.Links.FeedbackLink)
}
</script>

<style lang="scss">
@use 'assets/extend';
@use 'assets/variables';

#Westfold {
  .v-social-networks {
    flex-direction: row-reverse;
  }

  .v-mobile-apps {
    background: variables.$Mono800;
    border-radius: variables.$BorderRadiusInput;
    padding: 0.57rem;

    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 1fr 1fr;
    gap: 0.855rem 0.57rem;
    grid-auto-flow: row;
    grid-template-areas:
      'qr text'
      'qr links';

    &__qr {
      grid-area: qr;

      img {
        width: 80px;
        height: 80px;
        border-radius: max(4px, calc(variables.$BorderRadiusInput - 0.57rem));
        overflow: hidden;
      }
    }

    &__text {
      grid-area: text;
    }

    &__links {
      grid-area: links;
    }
  }

  .v-footer-extra-links {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 0.285rem;
  }

  .v-footer-hr {
    @extend .v-no-hr-margin;
    padding: 0.1rem 0 0.855rem;

    hr {
      color: variables.$Mono800;
      border-top: variables.$Mono800;
    }
  }

  .v-footer-legal-logo {
    opacity: 0.8;

    &__caption {
      height: 1.15rem;
      margin-right: 0.57rem;
    }

    svg {
      height: 18px;
      width: auto;
    }
  }
}

.v-footer-westfold-feedback {
  width: 100%;
  padding: 0.715rem 1.43rem;
  border-radius: variables.$BorderRadiusInput;
  background: variables.$Mono800;
  color: variables.$Mono200;

  &:hover {
    background: variables.$Mono600;
  }
}
</style>
